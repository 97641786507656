import { Button, Form, Modal, Upload } from 'antd';
import React from 'react';
import _ from 'lodash';

interface ImportFileProps {
  callback: (params: { file: Blob; }) => Promise<unknown>;
  // eslint-disable-next-line @typescript-eslint/ban-types
  onCancel: Function;
  templateUrl?: string;
}

// 导入临时
export function ImportFile({
  callback,
  onCancel,
  templateUrl,
}: ImportFileProps) {
  const [form] = Form.useForm();
  const [
    paramData,
    setParamData,
  ] = React.useState<{ loading: boolean; params: { file: { file: Blob; }; }; }>({
    loading: false,
    params: null,
  });

  const handleFinish = React.useCallback((params) => {
    setParamData({
      loading: true,
      params,
    });
  }, []);

  React.useEffect(() => {
    if (paramData.params) {
      const {
        file,
        ...rest
      } = paramData.params;
      callback({
        file: file.file,
        ...rest,
      })
        .catch(() => setParamData({
          loading: false,
          params: null,
        }));
    }
  }, [
    callback,
    paramData,
  ]);
  const handleDownloadTemplate = React.useCallback(_.debounce(() => {
    if (templateUrl) {
      window.open(templateUrl);
    }
  }, 1000), [templateUrl]);

  return (
    <Modal
      centered
      maskClosable={false}
      okButtonProps={{ loading: paramData.loading }}
      onCancel={() => onCancel()}
      onOk={() => form.submit()}
      title="导入"
      visible
      width={400}
    >
      <Form
        form={form}
        initialValues={{}}
        labelCol={{ span: 6 }}
        layout="horizontal"
        onFinish={handleFinish}
        wrapperCol={{ span: 18 }}
      >
        <Form.Item
          label="文件"
          name="file"
          rules={[
            {
              required: true,
              message: '请选择文件',
            },
          ]}
        >
          <Upload
            accept=".xlsx"
            beforeUpload={() => false}
            maxCount={1}
          >
            <Button type="primary">
              选择文件
            </Button>
          </Upload>
        </Form.Item>
        <Form.Item label="模板">
          <Button
            onClick={handleDownloadTemplate}
            type="primary"
          >
            下载模板
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
}
