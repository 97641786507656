import { NormalProgramme } from 'egenie-utils';
import type { Store } from './store';
import _ from 'lodash';

export function normalProgramme(context: Store): NormalProgramme {
  return new NormalProgramme({
    filterItems: [
      {
        type: 'input',
        field: 'skuNo',
        label: 'sku编码',
        onChangeCallback: () => {
          context.skuImportItem = [];
        },
      },
      {
        type: 'input',
        field: 'uniqueCode',
        label: '唯一码',
      },
      {
        type: 'input',
        field: 'sellerOuterNo',
        label: '商品货号',
      },
      {
        type: 'input',
        field: 'productNo',
        label: '商品编码',
      },
      {
        type: 'input',
        field: 'bussinessNo',
        label: '单据编码',
      },
      {
        type: 'select',
        field: 'ownerId',
        label: '货主',
        onChangeCallback: (value) => {
          context.normalProgramme.filterItems.updateFilterItem([
            {
              field: 'viceSendOwnerId',
              value: undefined,
              disabled: Boolean(value),
            },
          ]);
        },
      },
      {
        type: 'select',
        field: 'uniqueCodeStatus',
        label: '唯一码状态',
      },
      {
        type: 'select',
        field: 'enable',
        label: '商品状态',
        data: [
          {
            value: '1',
            label: '启用',
          },
          {
            value: '0',
            label: '禁用',
          },
        ],
        value: '1',
      },
      {
        type: 'select',
        field: 'viceSendOwnerId',
        label: '代发货主',
        onChangeCallback: (value) => {
          context.normalProgramme.filterItems.updateFilterItem([
            {
              field: 'ownerId',
              value: undefined,
              disabled: Boolean(value),
            },
          ]);
        },
        validator: () => {
          const params = context.normalProgramme.filterItems.params;
          if (_.toString(params.viceSendOwnerId)) {
            if ([
              params.skuNo,
              params.uniqueCode,
              params.sellerOuterNo,
              params.productNo,
              params.bussinessNo,
            ].every((item) => !item)) {
              return Promise.reject('请选择: sku编码或者唯一码或者商品货号或者商品编码或者单据编码');
            }
          }

          return Promise.resolve(null);
        },
      },
    ],
    count: 6,
    handleSearch: () => {
      context.gridModel.current = 1;
      return context.gridModel.onQuery();
    },
  });
}
