import { Button, Input, Space } from 'antd';
import { formatNumber } from 'egenie-common';
import { EgGridModel, ImgFormatter, Permission } from 'egenie-utils';
import { Observer } from 'mobx-react';
import React from 'react';
import type { Store } from './store';

export function gridModel(context: Store): EgGridModel {
  return new EgGridModel({
    columns: [
      {
        key: 'operation',
        name: '操作',
        width: 160,
        frozen: true,
        formatter: ({ row }): JSX.Element => (
          <Space>
            <Permission permissionId="2656_2034">
              <a onClick={(e) => context.handleSinglePrint(e, row)}>
                打印唯一码
              </a>
            </Permission>
            <Permission permissionId="2656_6036">
              <a onClick={() => context.printSkuNo([row])}>
                打印SKU编码
              </a>
            </Permission>
          </Space>
        ),
      },
      {
        key: 'status',
        name: '本次打印状态',
        width: 100,
        formatter: ({ row }): JSX.Element => (
          <div style={{
            background: row.status ? '#d9f6ef' : '',
            textAlign: 'center',
          }}
          >
            {row.status ? '已打印' : '未打印'}
          </div>
        ),
      },
      {
        key: 'count',
        name: '打印数量',
        width: 100,
        editable: true,
        formatter: ({
          row,
          rowIdx,
        }) => (
          <Observer>
            {() => (
              <Input
                bordered={!(context.gridModel.cursorRow?.id === row.id)}
                disabled={row.uniqueCode}
                onChange={(e) => context.gridModel.rows[rowIdx].count = formatNumber(e.target.value)}
                onClick={(e) => e.stopPropagation()}
                onPressEnter={(e) => context.handleSinglePrint(e, row)}
                value={context.gridModel.rows[rowIdx].count}
              />
            )}
          </Observer>
        ),
      },
      {
        key: 'pic',
        name: '图片',
        width: 60,
        formatter: ({ row }): JSX.Element => (
          <ImgFormatter value={row.pic}/>
        ),
      },
      {
        key: 'color',
        name: '颜色',
        width: 100,
        sortable: true,
      },
      {
        key: 'size',
        name: '尺码',
        width: 100,
        sortable: true,
      },
      {
        key: 'uniqueCode',
        name: '唯一码',
        width: 200,
      },
      {
        key: 'skuNo',
        name: 'sku编码',
        width: 200,
      },
      {
        key: 'sellerOuterNo',
        name: '商品货号',
        sortable: true,
      },
      {
        key: 'uniqueCodeStatusName',
        name: '唯一码状态',
        width: 80,
      },
      {
        key: 'ownerName',
        name: '货主',
        width: 200,
      },
      {
        name: '供应商',
        key: 'vendorNo',
        width: 150,
      },
    ].map((info) => ({
      resizable: true,
      ...info,
    })),
    rows: [],
    rowHeight: 38,
    primaryKeyField: 'id',
    sortByLocal: false,
    pageSize: 50,
    showCheckBox: true,
    showReset: true,
    showPager: true,
    showRefresh: false,
    showSelectedTotal: true,
    setColumnsDisplay: true,
    gridIdForColumnConfig: '/egenie-cloud-wms/productSkuPrint/index',

    forceRowClick: true, // 点击行更新字表 数据
    api: {
      onQuery: context.handleQuery,
      onPageChange: (page: number, pageSize: number) => context.handleQuery({
        page,
        pageSize,
        sord: context.gridModel.sortDirection,
        sidx: context.gridModel.sortColumnKey,
      }),
      onShowSizeChange: (page: number, pageSize: number) => context.handleQuery({
        page,
        pageSize,
        sord: context.gridModel.sortDirection,
        sidx: context.gridModel.sortColumnKey,
      }),
      onSort: ({
        sidx,
        sord,
      }) => {
        context.gridModel.current = 1;
        context.handleQuery({
          sidx,
          sord,
          page: 1,
          pageSize: context.gridModel.pageSize,
        });
      },
    },
  });
}
