import { Card, Button } from 'antd';
import { NormalProgrammeComponent, EgGrid, Permission } from 'egenie-utils';
import { Provider, inject, observer } from 'mobx-react';
import React from 'react';
import styles from './index.less';
import { Store } from './store';

const store = new Store();

@inject('store')
@observer
class Content extends React.Component<{ store?: Store; }> {
  render() {
    const {
      normalProgramme,
      gridModel,
      handleBatchPrint,
      printCertificateOrWaterWashingLabel,
      handleImportFile,
      printSkuNo,
      printAuthStyle,
    } = this.props.store;
    return (
      <div className={styles.barcodePrint}>
        <Card size="small">
          <NormalProgrammeComponent store={normalProgramme}/>
        </Card>
        <div className={styles.tableBox}>
          <div className={styles.tableOperation}>
            <Permission permissionId="2656_5">
              <Button
                className={styles.batchPrint}
                onClick={handleImportFile}
                type="link"
              >
                导入
              </Button>
            </Permission>
            <Permission permissionId="2656_114">
              <Button
                className={styles.batchPrint}
                disabled={!gridModel.selectRows.length}
                onClick={() => printCertificateOrWaterWashingLabel('7')}
                type="link"
              >
                打印合格证
              </Button>
            </Permission>
            <Permission permissionId="2656_205">
              <Button
                className={styles.batchPrint}
                disabled={!gridModel.selectRows.length}
                onClick={() => printCertificateOrWaterWashingLabel('6')}
                type="link"
              >
                打印水洗唛
              </Button>
            </Permission>
            <Permission permissionId="2656_6056">
              <Button
                className={styles.batchPrint}
                disabled={!gridModel.selectRows.length}
                onClick={printAuthStyle}
                type="link"
              >
                打印认证款
              </Button>
            </Permission>
            <Permission permissionId="2656_6036">
              <Button
                className={styles.batchPrint}
                disabled={!gridModel.selectRows.length}
                onClick={() => printSkuNo(gridModel.selectRows)}
                type="link"
              >
                打印SKU编码
              </Button>
            </Permission>
            <Permission permissionId="2656_2034">
              <>
                <Button
                  className={styles.batchPrint}
                  disabled={!gridModel.selectRows.length}
                  onClick={handleBatchPrint}
                  type="link"
                >
                  打印唯一码
                </Button>
                <span>
                  （修改数量后按enter键可打印本条唯一码）
                </span>
              </>
            </Permission>
          </div>
          <div className={styles.tableItem}>
            <EgGrid store={gridModel}/>
          </div>
        </div>
      </div>
    );
  }
}

// 条码打印
export default class extends React.Component {
  render() {
    return (
      <Provider store={store}>
        <Content/>
      </Provider>
    );
  }
}
