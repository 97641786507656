import { message } from 'antd';
import { getCustomPrintParam, printHelper, renderModal, destroyAllModal, getStaticResourceUrl } from 'egenie-common';
import type { BaseData, FilterConfigData, PaginationData, NormalProgramme, EgGridModel } from 'egenie-utils';
import { formatFilterConfigData, request } from 'egenie-utils';
import _ from 'lodash';
import { action, observable } from 'mobx';
import { nanoid } from 'nanoid';
import React from 'react';
import { getConsignor, getOwner, ImportFile } from '../../utils';
import { gridModel } from './gridModel';
import type { SkuImportItem, DetailItem, MainItem } from './interface';
import { normalProgramme } from './normalProgramme';

export class Store {
  constructor() {
    this.gridModel = gridModel(this);
    this.normalProgramme = normalProgramme(this);

    getOwner()
      .then((info) => this.normalProgramme.filterItems.addDict({ ownerId: info }));

    getConsignor()
      .then((data) => this.normalProgramme.filterItems.addDict({ viceSendOwnerId: data }));

    request<FilterConfigData>({
      url: '/api/cloud/baseinfo/rest/filterSet2/getConfig2',
      params: {
        dictList: 'unique_code_status',
        module: '',
      },
    })
      .then((info) => {
        const list = formatFilterConfigData(info, { unique_code_status: 'uniqueCodeStatus' });
        this.normalProgramme.filterItems.addDict(list.reduce((prev, current) => ({
          ...prev,
          [current.field]: current.data,
        }), {}));
      });
  }

  public skuImportItem: SkuImportItem[] = [];

  @action public handleQuery = (data = {}): Promise<unknown> => {
    const params = this.normalProgramme.filterItems.params;

    return request<PaginationData<MainItem>>({
      url: '/api/cloud/baseinfo/rest/unique/code/query/page',
      method: 'POST',
      data: {
        ...data,
        ...params,
      },
    })
      .then((res) => {
        this.gridModel.rows = (res.data?.list || []).map((item) => ({
          ...item,
          count: this.skuImportItem.find((val) => val.skuNo === item.skuNo)?.count || 1,
          status: false,
          id: nanoid(),
        }));
        this.gridModel.total = res.data.totalCount;
      });
  };

  public gridModel: EgGridModel;

  @observable public normalProgramme: NormalProgramme;

  @action public handleBatchPrint = (): void => {
    if (!this.gridModel.selectRows.length) {
      message.error({
        key: '请勾选一条数据',
        content: '请勾选一条数据',
      });
      return;
    }

    this.printUniqueCode(this.gridModel.selectRows.map((item) => ({
      num: item.count,
      skuId: item.skuId,
      uniqueCode: item.uniqueCode,
      id: item.id,
    })));
  };

  @action public handleSinglePrint = (e, row: MainItem): void => {
    this.printUniqueCode([
      {
        num: row.count,
        skuId: row.skuId,
        uniqueCode: row.uniqueCode,
        id: row.id,
      },
    ]);
  };

  @action public printSkuNo = async(detailList: MainItem[]): Promise<void> => {
    if (detailList.length === 0) {
      const error = '请至少选择一条数据';
      message.error({
        key: error,
        content: error,
      });
      return;
    }

    const {
      tempType,
      preview,
      templateId,
      printer,
    } = await getCustomPrintParam('30');

    const printData = await request<BaseData<{ userDataList: any; tempData: any; }>>({
      url: '/api/cloud/baseinfo/rest/sku/print',
      method: 'POST',
      data: {
        skuDetail: detailList.map((item) => ({
          skuId: item.skuId,
          num: item.count,
          viceSendOwnerId: this.normalProgramme.filterItems.params.viceSendOwnerId,
        })),
        tempId: templateId || 0,
        tempType,
      },
    });

    printHelper.toggleToLodop();
    await printHelper.print({
      preview,
      printer,
      templateData: printData?.data?.tempData,
      contents: printData?.data?.userDataList,
    });
  };

  @action public printUniqueCode = async(detailList: DetailItem[]): Promise<void> => {
    const {
      tempType,
      preview,
      templateId,
      printer,
    } = await getCustomPrintParam('27');

    const printData = await request<BaseData<{ userDataList: any; tempData: any; }>>({
      url: '/api/cloud/baseinfo/rest/unique/code/print/unique/code',
      method: 'POST',
      data: {
        tempId: templateId || 0,
        tempType,
        detailList: detailList.map((item) => ({
          ...item,
          viceSendOwnerId: this.normalProgramme.filterItems.params.viceSendOwnerId,
        })),
      },
    });

    printHelper.toggleToLodop();
    await printHelper.print({
      preview,
      printer,
      templateData: printData?.data?.tempData,
      contents: printData?.data?.userDataList,
    });

    detailList.forEach((item) => {
      const index = _.findIndex(this.gridModel.rows, [
        'id',
        item.id,
      ]);
      if (index !== -1) {
        this.gridModel.rows[index].status = true;
      }
    });
  };

  // 打印认证款
  @action public printAuthStyle = async() => {
    const skuNumList: Array<{ skuId: number; number: number; }> = (this.gridModel.selectRows as MainItem[]).filter((item) => item.count >= 1)
      .map((item) => ({
        skuId: item.skuId,
        number: item.count,
        uniqueCode: item.uniqueCode,
      }));

    if (!skuNumList.length) {
      const error = '请选择数据,且一个sku数量大于等于1';
      message.error({
        key: error,
        content: error,
      });
      throw new Error(error);
    }

    const printParam = await getCustomPrintParam('31', { customUrl: '/api/cloud/baseinfo/rest/print/template/cloud/list' });

    const printData = await request<BaseData<{ userDataList: any[]; tempData: any; }>>({
      method: 'post',
      url: '/api/cloud/wms/rest/print/template/cloud/auth/style/print/data',
      data: {
        tempType: printParam.tempType,
        tempId: printParam.templateId,
        skuNumList,
        ownerId: this.normalProgramme.filterItems.params.viceSendOwnerId,
      },
    });

    await printHelper.lodopPrintPlugin.print({
      preview: printParam.preview,
      printer: printParam.printer,
      templateData: printData?.data?.tempData,
      contents: printData?.data?.userDataList,
      generateQrcodeImageParams: {
        match: /authStyle.*uniqueCode/i,
        logoSrc: getStaticResourceUrl('pc/ts/egenie-cloud-wms/others/printAuthStyleLogo.png'),
        logoWidth: 0.2,
        logoHeight: 0.2,
      },
    });
  };

  // 打印合格证和水洗唛
  @action public printCertificateOrWaterWashingLabel = async(templateType: string) => {
    const skuNumList: Array<{ skuId: number; number: number; }> = (this.gridModel.selectRows as MainItem[]).filter((item) => item.count >= 1)
      .map((item) => ({
        skuId: item.skuId,
        number: item.count,
        uniqueCode: item.uniqueCode,
      }));

    if (!skuNumList.length) {
      const error = '请选择数据,且一个sku数量大于等于1';
      message.error({
        key: error,
        content: error,
      });
      throw new Error(error);
    }

    const printParam = await getCustomPrintParam(
      templateType,
      {
        customUrl: '/api/cloud/wms/rest/print/template/list',
        customParams: {
          ids: skuNumList.map((item) => item.skuId)
            .join(','),
          idType: 1,
          ownerId: this.normalProgramme.filterItems.params.viceSendOwnerId,
        },
      });

    const printData = await request<BaseData<{ userData: any[]; tempData: any; }>>({
      method: 'post',
      url: '/api/cloud/wms/rest/print/template/sku/info',
      data: {
        ids: skuNumList.map((item) => item.skuId)
          .join(','),
        tempType: printParam.tempType,
        idType: 1,
        tempId: printParam.templateId,
        skuNumList,
        ownerId: this.normalProgramme.filterItems.params.viceSendOwnerId,
      },
    });

    printHelper.toggleToRookie();
    await printHelper.print({
      preview: printParam.preview,
      printer: printParam.printer,
      templateData: printData?.data?.tempData,
      contents: printData?.data?.userData,
    });
  };

  @action public handleImportFile = () => {
    renderModal(
      <ImportFile
        callback={((params) => {
          const fd = new FormData();
          for (const key in params) {
            if (Object.prototype.hasOwnProperty.call(params, key)) {
              fd.append(key, params[key]);
            }
          }

          return request<BaseData<{ otherSkuNos: SkuImportItem[]; }>>({
            url: '/api/cloud/baseinfo/rest/unique/code/wmsOtherStockOutSkuNoImport',
            method: 'post',
            data: fd,
          })
            .then((info) => {
              this.skuImportItem = info.data?.otherSkuNos || [];
              this.normalProgramme.filterItems.updateFilterItem([
                {
                  field: 'skuNo',
                  type: 'input',
                  value: this.skuImportItem.map((item) => item.skuNo)
                    .join(','),
                },
              ]);

              this.gridModel.onQuery();
              message.success('上传成功');
              destroyAllModal();
            });
        })}
        onCancel={destroyAllModal}
        templateUrl={getStaticResourceUrl('pc/ts/egenie-cloud-wms/downloadTemplate/条码打印导入模板.xlsx')}
      />
    );
  };
}
